import React, { useEffect, useState } from "react";

import Modal from "../actions/Modal";
import { Select, Option, Input } from "@material-tailwind/react";

function FilterModal({ columns, list, onFilter, onClose }) {
  const [selectedColumn, setSelectedColumn] = useState("");
  const [filterText, setFilterText] = useState("");

  const handleColumnChange = (event) => {
    setSelectedColumn(event);
  };

  const handleFilterTextChange = (event) => {
    setFilterText(event.target.value);
  };

  const filterData = (arr, key, value) => {
    const lowerCaseValue = value.toString().toLowerCase();

    return arr.filter((item) => {
      if (key === "bar_number" && item.chamber_members) {
        return item.chamber_members.some((member) => {
          const { bar_number } = member;
          return bar_number?.toLowerCase().includes(lowerCaseValue);
        });
      }

      if (key === "practice_areas") {
        return item.practice_areas?.some((area) =>
          area.name?.toLowerCase().includes(lowerCaseValue)
        );
      }

      if (key === "chamber_name" && item.name) {
        return item.name?.toLowerCase().includes(lowerCaseValue);
      }

      if (key === "u_name" && item.white_list) {
        const { first_name, last_name } = item.white_list;
        const fullName = `${first_name} ${last_name}`.toLowerCase();

        return fullName.includes(lowerCaseValue);
      }

      if (item.white_list) {
        const { first_name, last_name } = item.white_list;
        if (key === "first_name") {
          return first_name?.toLowerCase().includes(lowerCaseValue);
        } else if (key === "last_name") {
          return last_name?.toLowerCase().includes(lowerCaseValue);
        }
      }

      if (item.u_name) {
        const { first_name, last_name } = item.u_name;
        if (key === "first_name") {
          return first_name?.toLowerCase().includes(lowerCaseValue);
        } else if (key === "last_name") {
          return last_name?.toLowerCase().includes(lowerCaseValue);
        }
      }

      if (item.s_name) {
        const { first_name, last_name, bar_number } = item.s_name;
        if (key === "senior") {
          return (
            first_name?.toLowerCase().includes(lowerCaseValue) ||
            last_name?.toLowerCase().includes(lowerCaseValue) ||
            bar_number?.toLowerCase().includes(lowerCaseValue)
          );
        }
      }

      if (key === "region") {
        return item.region?.name?.toLowerCase().includes(lowerCaseValue);
      }

      return item[key]?.toString().toLowerCase().includes(lowerCaseValue);
    });
  };

  const handleFilter = () => {
    if (filterText.trim() === "") {
      onFilter(list);
    } else {
      const filteredList = filterData(list, selectedColumn, filterText);
      onFilter(filteredList);
    }
  };

  useEffect(() => {
    if (columns.includes("bar_number")) {
      setSelectedColumn("bar_number");
    } else if (columns.length > 0) {
      setSelectedColumn(columns[0]);
    }
  }, [columns]);

  return (
    <>
      <Modal
        title="Filter Data"
        size="sm"
        open={true}
        content={
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
            <div className="relative w-full max-w-xs">
              <Select
                onChange={handleColumnChange}
                value={selectedColumn}
                label="Select Column"
              >
                {columns.map((column) => (
                  <Option key={column} value={column}>
                    {column}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="relative w-full max-w-xs">
              <Input
                label="Type here"
                className="input input-bordered w-full"
                value={filterText}
                onChange={handleFilterTextChange}
              />
            </div>
          </div>
        }
        actionLabel="Filter"
        closeLabel="Close"
        onAction={handleFilter}
        onClose={onClose}
      />
    </>
  );
}

export default FilterModal;
