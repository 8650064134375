import dayjs from "dayjs";

function formatDate(dateString) {
  if (typeof dateString !== "string") {
    throw new Error("Invalid date input");
  }

  const originalDate = new Date(dateString);
  const formattedDate = originalDate.toISOString().split("T")[0];
  return formattedDate;
}

export default formatDate;
