import { handleApi } from "..";
import {
  FILTER_STANDING,
  GOOD_STANDING,
  NO_STANDING,
  STANDING_PRODUCT_YEAR,
} from "../../constants/api_constants";

export const getGoodStanding = async (id) => {
  return handleApi("get", `${GOOD_STANDING}/${id}`);
};

export const standingProduct = async () => {
  return handleApi("get", STANDING_PRODUCT_YEAR);
};

export const noStanding = async () => {
  return handleApi("get", NO_STANDING);
};

export const filterStanding = async (values) => {
  return handleApi("post", FILTER_STANDING, values);
};
