export const GET_BLACK_LIST_SUCCESS = "GET_BLACK_LIST_SUCCESS";
export const GET_BLACK_LIST_FAILURE = "GET_BLACK_LIST_FAILURE";

export const ADD_BLACK_LIST_SUCCESS = "ADD_BLACK_LIST_SUCCESS";
export const ADD_BLACK_LIST_FAILURE = "ADD_BLACK_LIST_FAILURE";

export const UPDATE_BLACK_LIST_SUCCESS = "UPDATE_BLACK_LIST_SUCCESS";
export const UPDATE_BLACK_LIST_FAILURE = "UPDATE_BLACK_LIST_FAILURE";

export const DELETE_BLACK_LIST_SUCCESS = "DELETE_BLACK_LIST_SUCCESS";
export const DELETE_BLACK_LIST_FAILURE = "DELETE_BLACK_LIST_FAILURE";
