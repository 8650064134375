export const GET_CONFERENCE_SUCCESS = "GET_CONFERENCE_SUCCESS";
export const GET_CONFERENCE_FAILURE = "GET_CONFERENCE_FAILURE";

export const GET_CONFERENCE_PARTICIPANT_SUCCESS =
  "GET_CONFERENCE_PARTICIPANT_SUCCESS";
export const GET_CONFERENCE_PARTICIPANT_FAILURE =
  "GET_CONFERENCE_PARTICIPANT_FAILURE";

export const ADD_CONFERENCE_SUCCESS = "ADD_CONFERENCE_SUCCESS";
export const ADD_CONFERENCE_FAILURE = "ADD_CONFERENCE_FAILURE";

export const UPDATE_CONFERENCE_SUCCESS = "UPDATE_CONFERENCE_SUCCESS";
export const UPDATE_CONFERENCE_FAILURE = "UPDATE_CONFERENCE_FAILURE";

export const MANUAL_CONFERENCE_SUCCESS = "MANUAL_CONFERENCE_SUCCESS";
export const MANUAL_CONFERENCE_FAILURE = "MANUAL_CONFERENCE_FAILURE";

export const DELETE_CONFERENCE_SUCCESS = "DELETE_CONFERENCE_SUCCESS";
export const DELETE_CONFERENCE_FAILURE = "DELETE_CONFERENCE_FAILURE";

export const GET_CONFERENCE_EXEMPT_SUCCESS = "GET_CONFERENCE_EXEMPT_SUCCESS";
export const GET_CONFERENCE_EXEMPT_FAILURE = "GET_CONFERENCE_EXEMPT_FAILURE";

export const ADD_CONFERENCE_EXEMPT_SUCCESS = "ADD_CONFERENCE_EXEMPT_SUCCESS";
export const ADD_CONFERENCE_EXEMPT_FAILURE = "ADD_CONFERENCE_EXEMPT_FAILURE";

export const CONFERENCE_PAID_SUCCESS = "CONFERENCE_PAID_SUCCESS";
export const CONFERENCE_PAID_FAILURE = "CONFERENCE_PAID_FAILURE";

export const CONFERENCE_REGISTER_SUCCESS = "CONFERENCE_REGISTER_SUCCESS";
export const CONFERENCE_REGISTER_FAILURE = "CONFERENCE_REGISTER_FAILURE";

export const CONFERENCE_NEEDS_SUCCESS = "CONFERENCE_NEEDS_SUCCESS";
export const CONFERENCE_NEEDS_FAILURE = "CONFERENCE_NEEDS_FAILURE";

export const DELETE_PART_SUCCESS = "DELETE_PART_SUCCESS";
export const DELETE_PART_FAILURE = "DELETE_PART_FAILURE";

export const CONFERENCE_FILTER_SUCCESS = "CONFERENCE_FILTER_SUCCESS";
export const CONFERENCE_FILTER_FAILURE = "CONFERENCE_FILTER_FAILURE";

export const DELETE_CONFERENCE_EXEMPT_SUCCESS =
  "DELETE_CONFERENCE_EXEMPT_SUCCESS";
export const DELETE_CONFERENCE_EXEMPT_FAILURE =
  "DELETE_CONFERENCE_EXEMPT_FAILURE";
