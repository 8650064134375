import * as types from "../../constants/payment/chamber_constants";

const initialState = {
  data: null,
  chamberError: null,
  product: null,
  message: null,
  pag: null,
  filter: null,
  details: null,
};

const chamberReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_CHAMBER_SUCCESS:
    case types.UPDATE_CHAMBER_SUCCESS:
    case types.ADD_CHAMBER_SUCCESS:
      return {
        ...state,
        data: payload ? payload.chambers : null,
        chamberError: null,
      };

    case types.FIRST_CHAMBER_PAY_SUCCESS:
      return {
        ...state,
        pag: payload ? payload.chambers : null,
        product: payload ? payload.product.name : null,
        chamberError: null,
      };

    case types.FILTER_CHAMBER_PAY_SUCCESS:
      return {
        ...state,
        filter: payload ? payload.chambers : null,
        product: payload ? payload.product.name : null,
        chamberError: null,
      };

    case types.DELETE_PAYMENT_CHAMBER_SUCCESS:
      return {
        ...state,
        message: payload ? payload.message : null,
        chamberError: null,
      };

    case types.GET_CHAMBER_DETAILS_SUCCESS:
    case types.MANUAL_CHAMBER_SUCCESS:
      return {
        ...state,
        details: payload ? payload.chambers : null,
        product: payload ? payload.product.name : null,
        chamberError: null,
      };

    case types.GET_CHAMBER_FAILURE:
    case types.ADD_CHAMBER_FAILURE:
    case types.UPDATE_CHAMBER_FAILURE:
    case types.GET_CHAMBER_DETAILS_FAILURE:
    case types.MANUAL_CHAMBER_FAILURE:
    case types.DELETE_PAYMENT_CHAMBER_FAILURE:
    case types.FILTER_CHAMBER_PAY_FAILURE:
      return { ...state, chamberError: payload ? payload : null };

    default:
      return state;
  }
};

export default chamberReducer;
