export const GET_DUES_EXEMPT_SUCCESS = "GET_DUES_EXEMPT_SUCCESS";
export const GET_DUES_EXEMPT_FAILURE = "GET_DUES_EXEMPT_FAILURE";

export const ADD_DUES_EXEMPT_SUCCESS = "ADD_DUES_EXEMPT_SUCCESS";
export const ADD_DUES_EXEMPT_FAILURE = "ADD_DUES_EXEMPT_FAILURE";

export const DELETE_DUES_EXEMPT_SUCCESS = "DELETE_DUES_EXEMPT_SUCCESS";
export const DELETE_DUES_EXEMPT_FAILURE = "DELETE_DUES_EXEMPT_FAILURE";

export const UPLOAD_DUES_EXEMPT_SUCCESS = "UPLOAD_DUES_EXEMPT_SUCCESS";
export const UPLOAD_DUES_EXEMPT_FAILURE = "UPLOAD_DUES_EXEMPT_FAILURE";
