import { Card, CardBody } from "@material-tailwind/react";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import dayjs from "dayjs";

import formatDate from "../../utils/formatDate";

function DataTable({
  rows,
  columns,
  showFilter,
  dateFormat,
  filteredResult,
  columnVisibilityModel,
}) {
  const formatDates = (row) => {
    if (!dateFormat) {
      return row;
    }
    return dateFormat.reduce(
      (formattedRow, fieldName) => {
        if (row[fieldName]) {
          formattedRow[fieldName] = formatDate(row[fieldName]);
        }

        return formattedRow;
      },
      { ...row }
    );
  };

  const addArrayIndex = (row, index) => {
    return { ...row, arrayIndex: index + 1 };
  };

  const addPeriodTo = (row) => {
    if (row.exp_date) {
      return { ...row, periodTo: dayjs(row.exp_date).diff(dayjs(), "day") };
    }
    return row;
  };

  const formattedRows = showFilter
    ? filteredResult.map((row, index) =>
        addPeriodTo(addArrayIndex(formatDates(row), index))
      )
    : rows.map((row, index) =>
        addPeriodTo(addArrayIndex(formatDates(row), index))
      );

  const initialGridState = {
    pagination: { paginationModel: { pageSize: 50 } },
    pageSizeOptions: [25, 50, 100],
  };

  if (columnVisibilityModel) {
    initialGridState.columns = { columnVisibilityModel };
  }

  const columnsWithArrayIndex = [
    {
      field: "arrayIndex",
      headerName: "ID",
      width: 80,
    },
    ...columns,
  ];

  return (
    <Card className="w-full p-6 shadow-xl mb-20">
      <CardBody>
        <DataGrid
          sx={{ border: 0 }}
          rows={formattedRows}
          columns={columnsWithArrayIndex}
          initialState={initialGridState}
          pageSizeOptions={[25, 50, 100]}
        />
      </CardBody>
    </Card>
  );
}

export default DataTable;
