import * as types from "../../constants/conference/conference_constants";

const initialState = {
  data: null,
  variety: null,
  conferenceError: null,
  product: null,
  exempt: null,
  message: null,
  search: null,
};

const conferenceReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_CONFERENCE_SUCCESS:
    case types.UPDATE_CONFERENCE_SUCCESS:
    case types.ADD_CONFERENCE_SUCCESS:
    case types.DELETE_CONFERENCE_SUCCESS:
      return {
        ...state,
        data: payload ? payload.conference : null,
        conferenceError: null,
      };

    case types.CONFERENCE_PAID_SUCCESS:
    case types.CONFERENCE_REGISTER_SUCCESS:
    case types.CONFERENCE_NEEDS_SUCCESS:
      return {
        ...state,
        variety: payload ? payload.list : null,
        conferenceError: null,
      };

    case types.DELETE_PART_SUCCESS:
      return {
        ...state,
        message: payload ? payload.message : null,
        conferenceError: null,
      };

    case types.GET_CONFERENCE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        data: payload ? payload.conference : null,
        product: payload ? payload.product : null,
        conferenceError: null,
      };

    case types.MANUAL_CONFERENCE_SUCCESS:
      return {
        ...state,
        message: payload ? payload.message : null,
        conferenceError: null,
      };

    case types.CONFERENCE_FILTER_SUCCESS:
      return {
        ...state,
        search: payload ? payload.search : null,
        conferenceError: null,
      };

    case types.ADD_CONFERENCE_EXEMPT_SUCCESS:
    case types.DELETE_CONFERENCE_EXEMPT_SUCCESS:
    case types.GET_CONFERENCE_EXEMPT_SUCCESS:
      return {
        ...state,
        exempt: payload ? payload.exempt : null,
        conferenceError: null,
      };

    case types.GET_CONFERENCE_FAILURE:
    case types.ADD_CONFERENCE_FAILURE:
    case types.UPDATE_CONFERENCE_FAILURE:
    case types.DELETE_CONFERENCE_FAILURE:
    case types.GET_CONFERENCE_PARTICIPANT_FAILURE:
    case types.MANUAL_CONFERENCE_FAILURE:
    case types.CONFERENCE_PAID_FAILURE:
    case types.CONFERENCE_REGISTER_FAILURE:
    case types.CONFERENCE_NEEDS_FAILURE:
    case types.DELETE_PART_FAILURE:
    case types.CONFERENCE_FILTER_FAILURE:
      return { ...state, conferenceError: payload ? payload : null };

    case types.ADD_CONFERENCE_EXEMPT_FAILURE:
    case types.DELETE_CONFERENCE_EXEMPT_FAILURE:
    case types.GET_CONFERENCE_EXEMPT_FAILURE:
      return { ...state, conferenceError: payload ? payload : null };

    default:
      return state;
  }
};

export default conferenceReducer;
