import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import FallbackLoading from "./common/loaders/FallbackLoading";
import Login from "./pages/auth/Login";
import Layout from "./containers/Layout";
import ProtectedRoutes from "./routes/protected_routes";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";

function App() {
  const userData = useSelector((state) => state.auth?.userData);

  return (
    <>
      <Suspense fallback={<FallbackLoading />}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<ProtectedRoutes userData={userData} />}>
            <Route path="/dashboard/*" element={<Layout />} />
          </Route>
        </Routes>
      </Suspense>

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{ style: { background: "#363636", color: "#fff" } }}
      />
    </>
  );
}
export default App;
