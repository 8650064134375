import * as types from "../../constants/um/good_standing_constants";

const initialState = {
  product: null,
  good: null,
  not: null,
  goodError: null,
  search: null,
};

const goodStandingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_GOODSTANDING_SUCCESS:
      return {
        ...state,
        good: payload ? payload.orders : null,
        goodError: null,
      };

    case types.STANDING_PRODUCT_SUCCESS:
      return {
        ...state,
        product: payload ? payload.product : null,
        goodError: null,
      };

    case types.NOT_GOODSTANDING_SUCCESS:
      return {
        ...state,
        not: payload ? payload.user : null,
        goodError: null,
      };

    case types.FILTER_GOODSTANDING_SUCCESS:
      return {
        ...state,
        search: payload ? payload.search : null,
        goodError: null,
      };

    case types.GET_GOODSTANDING_FAILURE:
    case types.STANDING_PRODUCT_FAILURE:
    case types.NOT_GOODSTANDING_FAILURE:
      return { ...state, goodError: payload ? payload : null };

    default:
      return state;
  }
};

export default goodStandingReducer;
