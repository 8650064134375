export const LOGIN_DATA = {
  username: "",
  password: "",
};

export const WHITELIST_DATA = {
  id: "",
  bar_number: "",
  date_of_call: "",
  first_name: "",
  last_name: "",
  title: "",
};

export const BLACKLIST_DATA = {
  suspend_date: "",
  resume_date: "",
  bar_number: "",
  first_name: "",
  last_name: "",
};

export const DUES_EXEMPT_DATA = {
  first_name: "",
  last_name: "",
  bar_number: "",
};

export const DEPT_DUES_EXEMPT_DATA = {
  first_name: "",
  last_name: "",
  bar_number: "",
  department: "",
};

export const MANUAL_GREENLIST_DATA = {
  first_name: "",
  last_name: "",
  bar_number: "",
  cert_number: "",
  year: "",
};

export const BARREDLIST_DATA = {
  first_name: "",
  last_name: "",
  bar_number: "",
  disbar_date: "",
};

export const GLC_DATA = {
  first_name: "",
  last_name: "",
  bar_number: "",
  title: "",
  year: "",
};

export const LAWYERS_DATA = {
  bar_number: "",
  name: "",
  username: "",
  status: "",
  phone: "",
  role: "",
  status_date: null,
  resume_date: null,
  email: "",
};

export const PUPIL_DATA = {
  start_date: "",
  end_date: "",
  bar_number: "",
};

export const CHAMBER_DATA = {
  name: "",
  type: "",
  reg_num: "",
  vat_num: "",
  tin_num: "",
  ssnit_num: "",
  ghpost: "",
  postal_add: "",
  address: "",
  telephone: "",
  fax: "",
  email: "",
  staff_num: "",
  clerks_num: "",
  accounts_part: "",
  accounts_full: "",
  equipments: "",
  head: "",
  deputy: "",
  reports: "",
  rejected_note: "",
  files: {
    rgd: "",
    ssnit: "",
    vat: "",
    evidence: "",
  },
  practice_areas: [],
  p_region: "",
  city: "",
};

export const CHAMBER_MEMBER_DATA = {
  id: "",
  bar_number: "",
  status: "",
  title: "",
  first_name: "",
  last_name: "",
};

export const CONFERENCE_DATA = {
  id: "",
  name: "",
  year: "",
  start_date: "",
  end_date: "",
  others: "",
  less_than_ten: "",
  less_than_two: "",
  less_than_five: "",
  hotels: "",
  diet: false,
  food: false,
  tour: false,
  hotel: false,
  pickup: false,
  special: false,
  delivery: false,
  resources: "",
  notes: "",
  product_id: "",
  theme: "",
  speaker: "",
  venue: "",
  evening: "",
};

export const CONFERENCE_DETAILS = {
  id: "",
  full_name: "",
  bar_number: "",
  sub_type: "",
  amount: "",
  status: "",
  chamber: [],
};

export const CONFERENCE_EXEMPT_DATA = {
  first_name: "",
  last_name: "",
  bar_number: "",
};

export const DUES_DATA = {
  id: "",
  name: "",
  year: "",
  start_date: "",
  end_date: "",
  dues_one: "",
  dues_two: "",
  dues_three: "",
  gar: "",
  ash: "",
  cen: "",
  vol: "",
  bar: "",
  esr: "",
  wsr: "",
  nor: "",
  uwr: "",
  uer: "",
  notes: "",
  product_id: "",
};

export const LICENSE_DATA = {
  id: "",
  name: "",
  year: "",
  start_date: "",
  end_date: "",
  amount: "",
  bar_number: "",
};

export const CHAMBER_PAY_DATA = {
  id: "",
  name: "",
  year: "",
  application: "",
  renewal: "",
  start_date: "",
  end_date: "",
  product_id: "",
  notes: "",
  amount: "",
  url: "",
  chamberName: "",
  post_id: "",
};

export const PUPILAGE_DATA = {
  id: "",
  name: "",
  year: "",
  amount: "",
};

export const STICKER_DATA = {
  id: "",
  notes: "",
  isApproved: "",
};
