import { handleApi } from "./index";
import { GET_PROFILE, LOGIN_API } from "../constants/api_constants";

export const signIn = async (values) => {
  return handleApi("post", LOGIN_API, values);
};

export const getProfile = async () => {
  return handleApi("get", GET_PROFILE);
};
