export const GET_CHAMBER_SUCCESS = "GET_CHAMBER_SUCCESS";
export const GET_CHAMBER_FAILURE = "GET_CHAMBER_FAILURE";

export const ADD_CHAMBER_SUCCESS = "ADD_CHAMBER_SUCCESS";
export const ADD_CHAMBER_FAILURE = "ADD_CHAMBER_FAILURE";

export const UPDATE_CHAMBER_SUCCESS = "UPDATE_CHAMBER_SUCCESS";
export const UPDATE_CHAMBER_FAILURE = "UPDATE_CHAMBER_FAILURE";

export const GET_CHAMBER_DETAILS_SUCCESS = "GET_CHAMBER_DETAILS_SUCCESS";
export const GET_CHAMBER_DETAILS_FAILURE = "GET_CHAMBER_DETAILS_FAILURE";

export const MANUAL_CHAMBER_SUCCESS = "MANUAL_CHAMBER_SUCCESS";
export const MANUAL_CHAMBER_FAILURE = "MANUAL_CHAMBER_FAILURE";

export const DELETE_PAYMENT_CHAMBER_SUCCESS = "DELETE_PAYMENT_CHAMBER_SUCCESS";
export const DELETE_PAYMENT_CHAMBER_FAILURE = "DELETE_PAYMENT_CHAMBER_FAILURE";

export const FIRST_CHAMBER_PAY_SUCCESS = "FIRST_CHAMBER_PAY_SUCCESS";
export const FIRST_CHAMBER_PAY_FAILURE = "FIRST_CHAMBER_PAY_FAILURE";

export const FILTER_CHAMBER_PAY_SUCCESS = "FILTER_CHAMBER_PAY_SUCCESS";
export const FILTER_CHAMBER_PAY_FAILURE = "FILTER_CHAMBER_PAY_FAILURE";
