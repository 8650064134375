export const GET_LAWYER_SUCCESS = "GET_LAWYER_SUCCESS";
export const GET_LAWYER_FAILURE = "GET_LAWYER_FAILURE";

export const RESET_LAWYER_SUCCESS = "RESET_LAWYER_SUCCESS";
export const RESET_LAWYER_FAILURE = "RESET_LAWYER_FAILURE";

export const UPDATE_LAWYER_SUCCESS = "UPDATE_LAWYER_SUCCESS";
export const UPDATE_LAWYER_FAILURE = "UPDATE_LAWYER_FAILURE";

export const DELETE_LAWYER_SUCCESS = "DELETE_LAWYER_SUCCESS";
export const DELETE_LAWYER_FAILURE = "DELETE_LAWYER_FAILURE";

export const FIRST_LAWYER_SUCCESS = "FIRST_LAWYER_SUCCESS";
export const FIRST_LAWYER_FAILURE = "FIRST_LAWYER_FAILURE";

export const FILTER_LAWYER_SUCCESS = "FILTER_LAWYER_SUCCESS";
export const FILTER_LAWYER_FAILURE = "FILTER_LAWYER_FAILURE";

export const UPDATE_USER_SETTINGS_SUCCESS = "UPDATE_USER_SETTINGS_SUCCESS";
export const UPDATE_USER_SETTINGS_FAILURE = "UPDATE_USER_SETTINGS_FAILURE";
