function UMIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={30}
      height={30}
      viewBox="0 0 512 512"
      {...props}
    >
      {'{" "}'}
      <path
        d="m333.072 459.09-78.706 32.681-78.706-32.681c0-23.062 29.587-41.766 52.66-41.766h52.093c23.072 0 52.659 18.704 52.659 41.766z"
        style={{
          fill: "#fd7",
        }}
      />
      {'{" "}'}
      <path
        d="m354.86 459.09-16.34 10.894-16.34-10.894c0-23.062-18.693-41.766-41.766-41.766h32.681c23.071 0 41.765 18.704 41.765 41.766z"
        style={{
          fill: "#ffcc3d",
        }}
      />
      {'{" "}'}
      <path
        d="M195.638 417.324h32.681c-23.073 0-41.766 18.704-41.766 41.766l-16.34 10.894-16.34-10.894c-.001-23.062 18.693-41.766 41.765-41.766z"
        style={{
          fill: "#f9f0b5",
        }}
      />
      {'{" "}'}
      <path
        d="m323.007 59.12-68.641 21.787-68.641-21.787c0-17.364 35.862-31.428 53.226-31.428h30.829c17.365 0 53.227 14.064 53.227 31.428z"
        style={{
          fill: "#ffcc3d",
        }}
      />
      {'{" "}'}
      <path
        d="m333.9 59.12-16.34 10.894-16.34-10.894c0-17.364-14.075-31.428-31.439-31.428h32.681c17.364 0 31.438 14.064 31.438 31.428z"
        style={{
          fill: "#ffbb02",
        }}
      />
      {'{" "}'}
      <path
        d="M206.271 27.692h32.681c-17.364 0-31.439 14.064-31.439 31.428l-16.34 10.894-16.34-10.894c-.001-17.364 14.073-31.428 31.438-31.428z"
        style={{
          fill: "#fd7",
        }}
      />
      {'{" "}{" "}'}
      <path
        d="M357.873 315.898c10.325 26.672 36.206 45.594 66.522 45.594 30.317 0 56.197-18.922 66.523-45.594H357.873z"
        style={{
          fill: "#ffcc3d",
        }}
      />
      {'{" "}'}
      <path
        d="M86.516 361.492c30.317 0 56.197-18.922 66.522-45.594H19.993c10.325 26.672 36.206 45.594 66.523 45.594z"
        style={{
          fill: "#ffcc3d",
        }}
      />
      {'{" "}'}
      <path
        d="M398.434 500.856H110.298c0-23.073 40.481-41.766 63.553-41.766H334.88c23.073 0 63.554 18.694 63.554 41.766z"
        style={{
          fill: "#ffcc3d",
        }}
      />
      {'{" "}{" "}'}
      <path
        d="M409.328 500.856h-32.681c0-23.073-18.693-41.766-41.766-41.766h32.681c23.072 0 41.766 18.694 41.766 41.766z"
        style={{
          fill: "#ffbb02",
        }}
      />
      {'{" "}{" "}'}
      <path
        d="M141.17 459.09h32.681c-23.073 0-41.766 18.693-41.766 41.766H99.404c0-23.072 18.694-41.766 41.766-41.766z"
        style={{
          fill: "#fd7",
        }}
      />
      {'{" "}'}
      <path
        d="M150.103 59.119h208.536v32.746H150.103z"
        style={{
          fill: "#fd7",
        }}
      />
      {'{" "}{" "}{" "}'}
      <path
        d="M87.061 369.755c30.676 0 58.878-18.107 71.891-45.594h6.998a8.17 8.17 0 0 0 0-16.34H8.17a8.17 8.17 0 0 0 0 16.34h6.999c13.014 27.487 41.215 45.594 71.892 45.594zm53.247-45.594c-11.461 17.875-31.576 29.254-53.248 29.254s-41.786-11.378-53.248-29.254h106.496z"
        style={{
          fill: "#1d2328",
        }}
      />
      {'{" "}'}
      <path
        d="M503.83 307.82H346.05a8.17 8.17 0 0 0 0 16.34h6.998c13.014 27.487 41.215 45.594 71.891 45.594 30.676 0 58.878-18.107 71.891-45.594h6.999a8.17 8.17 0 0 0 .001-16.34zm-78.891 45.595c-21.672 0-41.786-11.378-53.248-29.254h106.495c-11.461 17.875-31.575 29.254-53.247 29.254z"
        style={{
          fill: "#1d2328",
        }}
      />
      {'{" "}'}
      <path
        d="M460.26 492.775h-41.806c-3.912-23.659-24.504-41.764-49.254-41.764h-5.202c-3.91-23.662-24.513-41.764-49.266-41.764H264.17V100.13h149.423l-62.925 188.214a8.17 8.17 0 0 0 5.158 10.339c4.279 1.434 8.909-.878 10.339-5.158l58.774-175.798 58.774 175.798a8.172 8.172 0 0 0 7.748 5.582 8.17 8.17 0 0 0 7.75-10.763L436.285 100.13h13.39a8.17 8.17 0 0 0 0-16.34H264.17V35.95h39.926c12.827 0 23.263 10.436 23.263 23.263a8.17 8.17 0 0 0 8.17 8.17h24.736a8.17 8.17 0 0 0 0-16.34h-17.412c-3.776-17.931-19.72-31.434-38.757-31.434H264.17v-8.555a8.17 8.17 0 0 0-16.34 0v8.555h-39.926c-19.038 0-34.98 13.504-38.757 31.434h-17.412a8.17 8.17 0 0 0 0 16.34h24.736a8.17 8.17 0 0 0 8.17-8.17c0-12.827 10.436-23.263 23.263-23.263h39.926v47.84H62.325a8.17 8.17 0 0 0 0 16.34h13.39L12.79 288.345a8.17 8.17 0 0 0 5.158 10.339 8.166 8.166 0 0 0 10.339-5.158L87.06 117.727l58.774 175.798a8.172 8.172 0 0 0 7.748 5.582 8.17 8.17 0 0 0 7.75-10.763L98.406 100.13H247.83v309.117h-50.561c-24.752 0-45.356 18.102-49.266 41.764h-5.202c-24.75 0-45.343 18.105-49.254 41.764H51.74a8.17 8.17 0 0 0 0 16.34h408.52a8.17 8.17 0 0 0 0-16.34zm-317.459-25.423h12.704a8.17 8.17 0 0 0 8.17-8.17c0-18.523 15.07-33.594 33.594-33.594h117.462c18.523 0 33.594 15.07 33.594 33.594a8.17 8.17 0 0 0 8.17 8.17h12.704c15.706 0 28.925 10.836 32.584 25.424H110.217c3.659-14.588 16.878-25.424 32.584-25.424z"
        style={{
          fill: "#1d2328",
        }}
      />
      {'{" "}'}
      <path
        d="M323.814 451.012H188.186a8.17 8.17 0 0 0 0 16.34h135.628a8.17 8.17 0 0 0 0-16.34z"
        style={{
          fill: "#1d2328",
        }}
      />
      {'{" "}{" "}'}
    </svg>
  );
}

export default UMIcon;
