export const GET_DEPT_DUES_SUCCESS = "GET_DEPT_DUES_SUCCESS";
export const GET_DEPT_DUES_FAILURE = "GET_DEPT_DUES_FAILURE";

export const ADD_DEPT_DUES_SUCCESS = "ADD_DEPT_DUES_SUCCESS";
export const ADD_DEPT_DUES_FAILURE = "ADD_DEPT_DUES_FAILURE";

export const DELETE_DEPT_DUES_SUCCESS = "DELETE_DEPT_DUES_SUCCESS";
export const DELETE_DEPT_DUES_FAILURE = "DELETE_DEPT_DUES_FAILURE";

export const UPLOAD_DEPT_DUES_SUCCESS = "UPLOAD_DEPT_DUES_SUCCESS";
export const UPLOAD_DEPT_DUES_FAILURE = "UPLOAD_DEPT_DUES_FAILURE";

export const UPDATE_DEPT_DUES_SUCCESS = "UPDATE_DEPT_DUES_SUCCESS";
export const UPDATE_DEPT_DUES_FAILURE = "UPDATE_DEPT_DUES_FAILURE";
