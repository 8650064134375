import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
} from "@material-tailwind/react";

function Modal({
  title,
  content,
  closeLabel,
  loading,
  actionLabel,
  onAction,
  onClose,
  size,
  open,
  handler,
  containerStyle,
}) {
  return (
    <Dialog
      size={size ? size : "sm"}
      open={open}
      handler={handler}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <DialogHeader>{title}</DialogHeader>
      {/* <DialogBody className={`max-h-[42rem] overflow-scroll ${containerStyle}`}> */}
      <DialogBody className={`${containerStyle}`}>{content}</DialogBody>
      <DialogFooter>
        {closeLabel && (
          <Button variant="text" color="red" onClick={onClose} className="mr-1">
            {closeLabel}
          </Button>
        )}
        {loading ? (
          <Button variant="filled" className="flex items-center gap-3">
            <Spinner className="h-4 w-4" />
          </Button>
        ) : (
          <Button variant="filled" onClick={onAction}>
            {actionLabel}
          </Button>
        )}
      </DialogFooter>
    </Dialog>
  );
}

export default Modal;
