import * as types from "../../constants/lawyer_list/manual_green_list_constants";

const initialState = {
  data: null,
  manualError: null,
};

const manualGreenListReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_MANUAL_GREEN_LIST_SUCCESS:
    case types.UPDATE_MANUAL_GREEN_LIST_SUCCESS:
    case types.ADD_MANUAL_GREEN_LIST_SUCCESS:
    case types.DELETE_MANUAL_GREEN_LIST_SUCCESS:
      return {
        ...state,
        data: payload ? payload.manualList : null,
        manualError: null,
      };

    case types.GET_MANUAL_GREEN_LIST_FAILURE:
    case types.ADD_MANUAL_GREEN_LIST_FAILURE:
    case types.UPDATE_MANUAL_GREEN_LIST_FAILURE:
    case types.DELETE_MANUAL_GREEN_LIST_FAILURE:
      return { ...state, manualError: payload ? payload : null };

    default:
      return state;
  }
};

export default manualGreenListReducer;
