export const GET_WHITE_LIST_SUCCESS = "GET_WHITE_LIST_SUCCESS";
export const GET_WHITE_LIST_FAILURE = "GET_WHITE_LIST_FAILURE";

export const GET_WHITE_LIST_PAG_SUCCESS = "GET_WHITE_LIST_PAG_SUCCESS";
export const GET_WHITE_LIST_PAG_FAILURE = "GET_WHITE_LIST_PAG_FAILURE";

export const UPDATE_WHITE_LIST_SUCCESS = "UPDATE_WHITE_LIST_SUCCESS";
export const UPDATE_WHITE_LIST_FAILURE = "UPDATE_WHITE_LIST_FAILURE";

export const UPLOAD_WHITE_LIST_SUCCESS = "UPLOAD_WHITE_LIST_SUCCESS";
export const UPLOAD_WHITE_LIST_FAILURE = "UPLOAD_WHITE_LIST_FAILURE";

export const GET_FILTER_WHITE_LIST_SUCCESS = "GET_FILTER_WHITE_LIST_SUCCESS";
export const GET_FILTER_WHITE_LIST_FAILURE = "GET_FILTER_WHITE_LIST_FAILURE";

export const ADD_WHITE_LIST_SUCCESS = "ADD_WHITE_LIST_SUCCESS";
export const ADD_WHITE_LIST_FAILURE = "ADD_WHITE_LIST_FAILURE";
