import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function SideBarContent({ icon, title, links, sectionNumber }) {
  const [open, setOpen] = useState(1);

  const handleOpen = (path) => {
    setOpen(open === path ? 0 : path);
  };

  return (
    <List>
      <Accordion
        open={open === sectionNumber}
        icon={
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`mx-auto h-4 w-4
  transition-transform ${open === sectionNumber ? "rotate-180" : ""}`}
          />
        }
      >
        <ListItem className="p-0" selected={open === sectionNumber}>
          <AccordionHeader
            onClick={() => handleOpen(sectionNumber)}
            className="border-b-0 p-3"
          >
            <ListItemPrefix>{icon}</ListItemPrefix>
            <Typography color="blue-gray" className="mr-auto font-normal">
              {title}
            </Typography>
          </AccordionHeader>
        </ListItem>
        <AccordionBody className="py-1">
          <List className="p-0">
            {links.map((link, index) => (
              <Link key={index} to={link.to}>
                <ListItem>
                  <ListItemPrefix>
                    <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                  </ListItemPrefix>
                  {link.label}
                </ListItem>
              </Link>
            ))}
          </List>
        </AccordionBody>
      </Accordion>
    </List>
  );
}

export default SideBarContent;
