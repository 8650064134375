import * as types from "../../constants/lawyer_list/barred_list_constants";

const initialState = {
  data: null,
  barredError: null,
};

const barredListReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_BARRED_LIST_SUCCESS:
    case types.UPDATE_BARRED_LIST_SUCCESS:
    case types.ADD_BARRED_LIST_SUCCESS:
    case types.DELETE_BARRED_LIST_SUCCESS:
      return {
        ...state,
        data: payload ? payload.barredUser : null,
        barredError: null,
      };

    case types.GET_BARRED_LIST_FAILURE:
    case types.ADD_BARRED_LIST_FAILURE:
    case types.UPDATE_BARRED_LIST_FAILURE:
    case types.DELETE_BARRED_LIST_FAILURE:
      return { ...state, barredError: payload ? payload : null };

    default:
      return state;
  }
};

export default barredListReducer;
