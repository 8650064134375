import LeftSideBar from "./LeftSideBar";
import PageContent from "./PageContent";

function Layout() {
  return (
    <>
      <div className="flex h-screen">
        <LeftSideBar />
        <PageContent />
      </div>
    </>
  );
}

export default Layout;
