import * as types from "../../constants/bootcamp/bootcamp_constants";

const initialState = {
  data: null,
  single: null,
  bootcampError: null,
  message: null,
};

const bootcampReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.BOOTCAMP_LIST_SUCCESS:
      return {
        ...state,
        data: payload ? payload.bootcamp : null,
        bootcampError: null,
      };

    case types.BOOTCAMP_SINGLE_SUCCESS:
      return {
        ...state,
        single: payload ? payload.bootcamp : null,
        bootcampError: null,
      };

    case types.BOOTCAMP_MANUAL_SUCCESS:
      return {
        ...state,
        message: payload ? payload : null,
        bootcampError: null,
      };

    case types.BOOTCAMP_LIST_FAILURE:
    case types.BOOTCAMP_MANUAL_FAILURE:
    case types.BOOTCAMP_SINGLE_FAILURE:
      return { ...state, bootcampError: payload ? payload : null };

    default:
      return state;
  }
};

export default bootcampReducer;
