export const GET_PUPIL_SUCCESS = "GET_PUPIL_SUCCESS";
export const GET_PUPIL_FAILURE = "GET_PUPIL_FAILURE";

export const UPDATE_PUPIL_SUCCESS = "UPDATE_PUPIL_SUCCESS";
export const UPDATE_PUPIL_FAILURE = "UPDATE_PUPIL_FAILURE";

export const DELETE_PUPIL_SUCCESS = "DELETE_PUPIL_SUCCESS";
export const DELETE_PUPIL_FAILURE = "DELETE_PUPIL_FAILURE";

export const FIRST_PUPIL_SUCCESS = "FIRST_PUPIL_SUCCESS";
export const FIRST_PUPIL_FAILURE = "FIRST_PUPIL_FAILURE";

export const FILTER_PUPIL_SUCCESS = "FILTER_PUPIL_SUCCESS";
export const FILTER_PUPIL_FAILURE = "DELETE_PUPIL_FAILURE";
