import * as types from "../../constants/payment/pupilage_constants";

const initialState = {
  data: null,
  pupilageError: null,
  product: null,
  message: null,
  details: null,
};

const pupilageReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_PUPILAGE_SUCCESS:
    case types.UPDATE_PUPILAGE_SUCCESS:
    case types.ADD_PUPILAGE_SUCCESS:
      return {
        ...state,
        data: payload ? payload.pupilage : null,
        pupilageError: null,
      };

    case types.FIRST_PUPILAGE_SUCCESS:
      return {
        ...state,
        pag: payload ? payload.pupilage : null,
        product: payload ? payload.product.name : null,
        pupilageError: null,
      };

    case types.FILTER_PUPILAGE_SUCCESS:
      return {
        ...state,
        filter: payload ? payload.pupilage : null,
        product: payload ? payload.product.name : null,
        pupilageError: null,
      };

    case types.DELETE_PUPILAGE_SUCCESS:
      return {
        ...state,
        message: payload ? payload.message : null,
        pupilageError: null,
      };

    case types.GET_PUPILAGE_DETAILS_SUCCESS:
      return {
        ...state,
        details: payload ? payload.pupilage : null,
        product: payload ? payload.product.name : null,
        pupilageError: null,
      };

    case types.GET_PUPILAGE_FAILURE:
    case types.ADD_PUPILAGE_FAILURE:
    case types.UPDATE_PUPILAGE_FAILURE:
    case types.GET_PUPILAGE_DETAILS_FAILURE:
    case types.DELETE_PUPILAGE_FAILURE:
    case types.FILTER_PUPILAGE_FAILURE:
      return { ...state, pupilageError: payload ? payload : null };

    default:
      return state;
  }
};

export default pupilageReducer;
