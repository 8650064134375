import * as types from "../../constants/payment/dues_constants";

const initialState = {
  data: null,
  duesError: null,
  product: null,
  message: null,
  pag: null,
  filter: null,
  details: null,
};

const duesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_DUES_SUCCESS:
    case types.UPDATE_DUES_SUCCESS:
    case types.ADD_DUES_SUCCESS:
      return { ...state, data: payload ? payload.dues : null, duesError: null };

    case types.DELETE_DUES_SUCCESS:
      return {
        ...state,
        message: payload ? payload.message : null,
        duesError: null,
      };

    case types.FIRST_DUES_SUCCESS:
      return {
        ...state,
        pag: payload ? payload.dues : null,
        product: payload ? payload.product.name : null,
        duesError: null,
      };

    case types.FILTER_DUES_SUCCESS:
      return {
        ...state,
        filter: payload ? payload.dues : null,
        product: payload ? payload.product.name : null,
        duesError: null,
      };

    case types.GET_DUES_DETAILS_SUCCESS:
    case types.MANUAL_DUES_SUCCESS:
      return {
        ...state,
        details: payload ? payload.dues : null,
        product: payload ? payload.product.name : null,
        duesError: null,
      };

    case types.GET_DUES_FAILURE:
    case types.ADD_DUES_FAILURE:
    case types.UPDATE_DUES_FAILURE:
    case types.GET_DUES_DETAILS_FAILURE:
    case types.MANUAL_DUES_FAILURE:
    case types.DELETE_DUES_FAILURE:
    case types.FILTER_DUES_FAILURE:
      return { ...state, duesError: payload ? payload : null };

    default:
      return state;
  }
};

export default duesReducer;
