import * as types from "../../constants/um/pupil_constants";

const initialState = {
  data: null,
  pupilError: null,
  pag: null,
  filter: null,
};

const pupilReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_PUPIL_SUCCESS:
    case types.UPDATE_PUPIL_SUCCESS:
    case types.DELETE_PUPIL_SUCCESS:
      return {
        ...state,
        data: payload ? payload.pupil : null,
        pupilError: null,
      };

    case types.FIRST_PUPIL_SUCCESS:
      return { ...state, pag: payload ? payload : null, pupilError: null };

    case types.FILTER_PUPIL_SUCCESS:
      return { ...state, filter: payload ? payload : null, pupilError: null };

    case types.GET_PUPIL_FAILURE:
    case types.UPDATE_PUPIL_FAILURE:
    case types.DELETE_PUPIL_FAILURE:
    case types.FILTER_PUPIL_FAILURE:
      return { ...state, pupilError: payload ? payload : null };

    default:
      return state;
  }
};

export default pupilReducer;
