import Papa from "papaparse";
import { saveAs } from "file-saver";

async function saveDownload(data, title) {
  if (!data || data.length === 0) {
    throw new Error("Data is empty.");
  }

  try {
    const flattenedData = data.map((item) => {
      const flattenedItem = { ...item };
      for (const key in flattenedItem) {
        if (
          Array.isArray(flattenedItem[key]) ||
          typeof flattenedItem[key] === "object"
        ) {
          flattenedItem[key] = JSON.stringify(flattenedItem[key]);
        }
      }
      return flattenedItem;
    });

    const csvContent = Papa.unparse(flattenedData);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    await saveAs(blob, `${title}.csv`);
  } catch (error) {
    throw new Error(
      "An error occurred while generating or saving the CSV file."
    );
  }
}

export default saveDownload;
