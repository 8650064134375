export const GET_MANUAL_GREEN_LIST_SUCCESS = "GET_MANUAL_GREEN_LIST_SUCCESS";
export const GET_MANUAL_GREEN_LIST_FAILURE = "GET_MANUAL_GREEN_LIST_FAILURE";

export const ADD_MANUAL_GREEN_LIST_SUCCESS = "ADD_MANUAL_GREEN_LIST_SUCCESS";
export const ADD_MANUAL_GREEN_LIST_FAILURE = "ADD_MANUAL_GREEN_LIST_FAILURE";

export const UPDATE_MANUAL_GREEN_LIST_SUCCESS =
  "UPDATE_MANUAL_GREEN_LIST_SUCCESS";
export const UPDATE_MANUAL_GREEN_LIST_FAILURE =
  "UPDATE_MANUAL_GREEN_LIST_FAILURE";

export const DELETE_MANUAL_GREEN_LIST_SUCCESS =
  "DELETE_MANUAL_GREEN_LIST_SUCCESS";
export const DELETE_MANUAL_GREEN_LIST_FAILURE =
  "DELETE_MANUAL_GREEN_LIST_FAILURE";
