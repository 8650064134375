import * as types from "../../constants/lawyer_list/dues_exempt_constants";

const initialState = {
  data: null,
  duesError: null,
};

const duesExemptReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_DUES_EXEMPT_SUCCESS:
    case types.ADD_DUES_EXEMPT_SUCCESS:
    case types.UPLOAD_DUES_EXEMPT_SUCCESS:
    case types.DELETE_DUES_EXEMPT_SUCCESS:
      return {
        ...state,
        data: payload ? payload.duesUser : null,
        duesError: null,
      };

    case types.GET_DUES_EXEMPT_FAILURE:
    case types.ADD_DUES_EXEMPT_FAILURE:
    case types.DELETE_DUES_EXEMPT_FAILURE:
    case types.UPLOAD_DUES_EXEMPT_FAILURE:
      return { ...state, duesError: payload ? payload : null };

    default:
      return state;
  }
};

export default duesExemptReducer;
