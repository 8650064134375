import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { standingProduct } from "../../redux/actions/um/good_standing_action";
import MoreDetailsIconButton from "../../common/buttons/MoreDetailsIconButton";
import { useNavigate } from "react-router-dom";
import DataTable from "../../common/shared/DataTable";
import PageTitle from "../../common/shared/PageTitle";
import UMIcon from "../../common/icons/UMIcon";
import formatNumber from "../../utils/formatNumber";
import Loader from "../../common/loaders/Loader";
import AlertMessage from "../../common/actions/AlertMessage";

function StandingProductYear() {
  const [loading, setLoading] = useState(true);

  const { product, goodError } = useSelector((state) => state.good);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCategory = (params) => {
    navigate(`/dashboard/standing/${params.row.year}`);
  };

  const columns = [
    { field: "name", headerName: "Title", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <div>
          <MoreDetailsIconButton onClick={() => handleCategory(params)} />
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(standingProduct()).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <>
      {goodError && <AlertMessage type="error" message={goodError} />}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between p-4">
            <div className="flex items-center">
              <PageTitle
                title={"Good Standing By Year"}
                subheading={formatNumber(product.length)}
                icon={<UMIcon />}
              />
            </div>
          </div>

          <DataTable rows={product} columns={columns} />
        </>
      )}
    </>
  );
}

export default StandingProductYear;
