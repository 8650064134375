import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getGoodStanding } from "../../redux/actions/um/good_standing_action";
import Loader from "../../common/loaders/Loader";
import AlertMessage from "../../common/actions/AlertMessage";
import PageTitle from "../../common/shared/PageTitle";
import formatNumber from "../../utils/formatNumber";
import UMIcon from "../../common/icons/UMIcon";
import DownloadButton from "../../common/buttons/DownloadButton";
import saveDownload from "../../utils/downloadFile";
import DataTable from "../../common/shared/DataTable";
import FilterButton from "../../common/buttons/FilterButton";
import ClearFilterButton from "../../common/buttons/ClearFilterButton";
import FilterModal from "../../common/shared/FilterModal";

function StandingYear() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [filteredResult, setFilteredResult] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [totalData, setTotalData] = useState([]);

  const { good, goodError } = useSelector((state) => state.good);

  const dispatch = useDispatch();

  const handleFilter = (filteredList) => {
    setFilteredResult(filteredList);
    setFilterModal(false);
    setShowFilter(true);
  };

  const columns = [
    {
      field: "name",
      headerName: "Full Name",
      flex: 1,
    },
    { field: "bar_number", headerName: "Bar Number", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
  ];

  useEffect(() => {
    dispatch(getGoodStanding(id)).finally(() => setLoading(false));
  }, [dispatch, id]);

  useEffect(() => {
    if (good) {
      setTotalData(good);
    }
  }, [good]);

  return (
    <>
      {goodError && <AlertMessage type="error" message={goodError} />}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between p-4">
            <div className="flex items-center">
              <PageTitle
                title={`${parseInt(id)}/${parseInt(id) + 1} GoodStanding List`}
                subheading={formatNumber(
                  showFilter ? filteredResult.length : totalData.length
                )}
                icon={<UMIcon />}
              />
            </div>

            <div className="flex items-center gap-4">
              <DownloadButton
                onClick={() =>
                  saveDownload(
                    good,
                    showFilter ? filteredResult : totalData,
                    `${parseInt(id)}/${parseInt(id) + 1} GoodStanding List`
                  )
                }
              />

              <FilterButton onClick={() => setFilterModal(true)} />
              {showFilter && (
                <ClearFilterButton onClick={() => setShowFilter(false)} />
              )}
            </div>
          </div>

          <DataTable
            rows={good}
            columns={columns}
            showFilter={showFilter}
            // dateFormat={["date_paid"]}
            filteredResult={filteredResult}
          />

          {filterModal && (
            <FilterModal
              columns={["bar_number", "name"]}
              list={totalData}
              onFilter={handleFilter}
              onClose={() => setFilterModal(false)}
            />
          )}
        </>
      )}
    </>
  );
}

export default StandingYear;
