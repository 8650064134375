export const GET_GOODSTANDING_SUCCESS = "GET_GOODSTANDING_SUCCESS";
export const GET_GOODSTANDING_FAILURE = "GET_GOODSTANDING_FAILURE";

export const STANDING_PRODUCT_SUCCESS = "STANDING_PRODUCT_SUCCESS";
export const STANDING_PRODUCT_FAILURE = "STANDING_PRODUCT_FAILURE";

export const NOT_GOODSTANDING_SUCCESS = "NOT_GOODSTANDING_SUCCESS";
export const NOT_GOODSTANDING_FAILURE = "NOT_GOODSTANDING_FAILURE";

export const FILTER_GOODSTANDING_SUCCESS = "FILTER_GOODSTANDING_SUCCESS";
export const FILTER_GOODSTANDING_FAILURE = "FILTER_GOODSTANDING_FAILURE";
