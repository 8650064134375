export const GET_BARRED_LIST_SUCCESS = "GET_BARRED_LIST_SUCCESS";
export const GET_BARRED_LIST_FAILURE = "GET_BARRED_LIST_FAILURE";

export const ADD_BARRED_LIST_SUCCESS = "ADD_BARRED_LIST_SUCCESS";
export const ADD_BARRED_LIST_FAILURE = "ADD_BARRED_LIST_FAILURE";

export const UPDATE_BARRED_LIST_SUCCESS = "UPDATE_BARRED_LIST_SUCCESS";
export const UPDATE_BARRED_LIST_FAILURE = "UPDATE_BARRED_LIST_FAILURE";

export const DELETE_BARRED_LIST_SUCCESS = "DELETE_BARRED_LIST_SUCCESS";
export const DELETE_BARRED_LIST_FAILURE = "DELETE_BARRED_LIST_FAILURE";
