import { CubeIcon, FilmIcon } from "@heroicons/react/24/solid";
import {
  SquaresPlusIcon,
  UsersIcon,
  BuildingLibraryIcon,
  BanknotesIcon,
  TicketIcon,
  NewspaperIcon,
} from "@heroicons/react/24/solid";

const routes = [
  {
    icon: <SquaresPlusIcon className="h-5 w-5" />,
    title: "Lawyer List",
    links: [
      { label: "White List", to: "/dashboard/whitelist" },
      { label: "Black List", to: "/dashboard/blacklist" },
      { label: "Dues Exempt", to: "/dashboard/dues-exempt" },
      { label: "Dept. Dues Exempt", to: "/dashboard/dept-dues-exempt" },
      { label: "Manual Green List", to: "/dashboard/manual-green-list" },
      { label: "Barred List", to: "/dashboard/barredlist" },
      { label: "GLC", to: "/dashboard/glc" },
    ],
    sectionNumber: 1,
  },
  {
    icon: <UsersIcon className="h-5 w-5" />,
    title: "User Management",
    links: [
      { label: "Lawyers", to: "/dashboard/lawyers" },
      { label: "Pupils", to: "/dashboard/pupils" },
      // { label: "Good Standing", to: "/dashboard/good" },
    ],
    sectionNumber: 2,
  },
  {
    icon: <CubeIcon className="h-5 w-5" />,
    title: "Good Standing",
    links: [
      { label: "Good Standing List", to: "/dashboard/standing" },
      { label: "Not For 3 Years", to: "/dashboard/not/standing" },
    ],
    sectionNumber: 3,
  },
  {
    icon: <FilmIcon className="h-5 w-5" />,
    title: "Military Bootcamp",
    links: [{ label: "Bootcamp List", to: "/dashboard/bootcamp" }],
    sectionNumber: 4,
  },
  {
    icon: <BuildingLibraryIcon className="h-5 w-5" />,
    title: "Chambers",
    links: [
      { label: "New Applications", to: "/dashboard/pending-chamber" },
      { label: "Active Chambers", to: "/dashboard/active-chamber" },
      { label: "InActive Chambers", to: "/dashboard/inactive-chamber" },
    ],
    sectionNumber: 5,
  },
  {
    icon: <TicketIcon className="h-5 w-5" />,
    title: "Conferences",
    links: [
      { label: "All Conference", to: "/dashboard/all-conference" },
      { label: "Conference Exempt", to: "/dashboard/exempt" },
    ],
    sectionNumber: 6,
  },
  {
    icon: <NewspaperIcon className="h-5 w-5" />,
    title: "Stickers",
    links: [
      {
        label: "Pending Approvals",
        to: "/dashboard/stickers/pending",
      },
      { label: "Approvals", to: "/dashboard/stickers/approved" },
      { label: "All Stickers", to: "/dashboard/stickers/all" },
    ],
    sectionNumber: 7,
  },
  {
    icon: <BanknotesIcon className="h-5 w-5" />,
    title: "Payments",
    links: [
      { label: "Dues", to: "/dashboard/dues" },
      { label: "License", to: "/dashboard/license" },
      { label: "Chamber", to: "/dashboard/chamber-payment" },
      { label: "Pupilage", to: "/dashboard/pupilage" },
      // { label: "Stickers", to: "/dashboard/stickers" },
    ],
    sectionNumber: 8,
  },
];

export default routes;
