export const GET_LICENSE_SUCCESS = "GET_LICENSE_SUCCESS";
export const GET_LICENSE_FAILURE = "GET_LICENSE_FAILURE";

export const ADD_LICENSE_SUCCESS = "ADD_LICENSE_SUCCESS";
export const ADD_LICENSE_FAILURE = "ADD_LICENSE_FAILURE";

export const UPDATE_LICENSE_SUCCESS = "UPDATE_LICENSE_SUCCESS";
export const UPDATE_LICENSE_FAILURE = "UPDATE_LICENSE_FAILURE";

export const GET_LICENSE_DETAILS_SUCCESS = "GET_LICENSE_DETAILS_SUCCESS";
export const GET_LICENSE_DETAILS_FAILURE = "GET_LICENSE_DETAILS_FAILURE";

export const MANUAL_LICENSE_SUCCESS = "MANUAL_LICENSE_SUCCESS";
export const MANUAL_LICENSE_FAILURE = "MANUAL_LICENSE_FAILURE";

export const DELETE_LICENSE_SUCCESS = "DELETE_LICENSE_SUCCESS";
export const DELETE_LICENSE_FAILURE = "DELETE_LICENSE_FAILURE";

export const FIRST_LICENSE_SUCCESS = "FIRST_LICENSE_SUCCESS";
export const FIRST_LICENSE_FAILURE = "FIRST_LICENSE_FAILURE";

export const FILTER_LICENSE_SUCCESS = "FILTER_LICENSE_SUCCESS";
export const FILTER_LICENSE_FAILURE = "FILTER_LICENSE_FAILURE";
