import * as types from "../../constants/lawyer_list/white_list_constants";

const initialState = {
  data: null,
  whiteListError: null,
  count: null,
  pag: null,
  filter: null,
  message: null,
};

const whiteListReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.UPLOAD_WHITE_LIST_SUCCESS:
      return { ...state, message: "Upload successful", whiteListError: null };

    case types.GET_WHITE_LIST_SUCCESS:
      return { ...state, data: payload ? payload : null, whiteListError: null };

    case types.UPDATE_WHITE_LIST_SUCCESS: {
      return { ...state, message: "Update successful", whiteListError: null };
    }

    case types.GET_WHITE_LIST_PAG_SUCCESS:
    case types.ADD_WHITE_LIST_SUCCESS:
      return { ...state, pag: payload ? payload : null, whiteListError: null };

    case types.GET_FILTER_WHITE_LIST_SUCCESS:
      return {
        ...state,
        filter: payload ? payload : null,
        whiteListError: null,
      };

    case types.GET_WHITE_LIST_FAILURE:
    case types.UPDATE_WHITE_LIST_FAILURE:
    case types.UPLOAD_WHITE_LIST_FAILURE:
    case types.GET_FILTER_WHITE_LIST_FAILURE:
    case types.GET_WHITE_LIST_PAG_FAILURE:
    case types.ADD_WHITE_LIST_FAILURE:
      return { ...state, whiteListError: payload ? payload : null };

    default:
      return state;
  }
};

export default whiteListReducer;
