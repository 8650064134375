import { Button, Card, Spinner } from "@material-tailwind/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { LOGIN_DATA } from "../../constants/constants";

import { signInAction } from "../../redux/actions/auth_actions";

import AlertMessage from "../../common/actions/AlertMessage";
import InputText from "../../common/forms/InputText";
import { useDispatch, useSelector } from "react-redux";

function Login() {
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState(LOGIN_DATA);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signInError = useSelector((state) => state.auth?.signInError);

  const updateFormValue = ({ updateType, value }) => {
    setLoginData({ ...loginData, [updateType]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await dispatch(signInAction(loginData, navigate)).finally(() =>
      setLoading(false)
    );
  };

  return (
    <div className="min-h-screen flex items-center">
      <Card className="mx-auto w-full max-w-xl shadow-xl">
        <div className="py-24 px-10">
          <div className="text-center">
            <img src="./gba-logo.png" alt="gba logo" className="inline-block" />
            {/* <Typography variant="h3" className="mb-2 mt-8">
          Login to Account
        </Typography> */}
          </div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="mb-16 mt-12">
              {signInError && (
                <AlertMessage type="error" message={signInError} />
              )}
              <InputText
                type="text"
                defaultValue={loginData.username}
                updateType="username"
                containerStyle="mt-4"
                labelTitle="Username"
                updateFormValue={updateFormValue}
                required={true}
              />

              <InputText
                type="password"
                defaultValue={loginData.password}
                updateType="password"
                containerStyle="mt-4"
                labelTitle="Password"
                updateFormValue={updateFormValue}
                required={true}
              />
            </div>

            <Button
              variant="filled"
              size="lg"
              fullWidth={true}
              className="text-center flex items-center justify-center"
              type="submit"
            >
              {loading ? <Spinner color="white" /> : "Login"}
            </Button>
          </form>
        </div>
      </Card>
    </div>
  );
}

export default Login;
