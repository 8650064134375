import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { setInitialAuthState } from "../redux/actions/auth_actions";

function ProtectedRoutes({ userData }) {
  const isAuthenticated = useMemo(() => {
    return (userData, accessToken) => {
      return userData && accessToken;
    };
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem("profile");
  const accessToken = JSON.parse(token)?.token;

  useEffect(() => {
    if (!isAuthenticated(userData, accessToken)) {
      dispatch(setInitialAuthState(navigate));
    }
  }, [dispatch, accessToken, isAuthenticated, navigate, userData]);

  return isAuthenticated(userData, accessToken) ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
}

export default ProtectedRoutes;
