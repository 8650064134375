import * as types from "../../constants/payment/license_constants";

const initialState = {
  data: null,
  licenseError: null,
  product: null,
  message: null,
  details: null,
};

const licenseReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_LICENSE_SUCCESS:
    case types.UPDATE_LICENSE_SUCCESS:
    case types.ADD_LICENSE_SUCCESS:
      return {
        ...state,
        data: payload ? payload.license : null,
        licenseError: null,
      };

    case types.FIRST_LICENSE_SUCCESS:
      return {
        ...state,
        pag: payload ? payload.license : null,
        product: payload ? payload.product.name : null,
        licenseError: null,
      };

    case types.FILTER_LICENSE_SUCCESS:
      return {
        ...state,
        filter: payload ? payload.license : null,
        product: payload ? payload.product.name : null,
        licenseError: null,
      };

    case types.DELETE_LICENSE_SUCCESS:
      return {
        ...state,
        message: payload ? payload.message : null,
        licenseError: null,
      };

    case types.GET_LICENSE_DETAILS_SUCCESS:
    case types.MANUAL_LICENSE_SUCCESS:
      return {
        ...state,
        details: payload ? payload.license : null,
        product: payload ? payload.product.name : null,
        licenseError: null,
      };

    case types.GET_LICENSE_FAILURE:
    case types.ADD_LICENSE_FAILURE:
    case types.UPDATE_LICENSE_FAILURE:
    case types.GET_LICENSE_DETAILS_FAILURE:
    case types.MANUAL_LICENSE_FAILURE:
    case types.DELETE_LICENSE_FAILURE:
    case types.FILTER_LICENSE_FAILURE:
      return { ...state, licenseError: payload ? payload : null };

    default:
      return state;
  }
};

export default licenseReducer;
