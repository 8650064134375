import { jwtDecode } from "jwt-decode";

export const tokenMiddleware = (store) => (next) => async (action) => {
  if (action.meta && action.meta.requiresAuth) {
    const state = store.getState();
    const token = state.auth?.accessToken;

    if (token) {
      const decodedToken = jwtDecode(token);
      const expirationTime = decodedToken.exp * 1000;
      const currentTime = Date.now();

      if (currentTime > expirationTime) {
        store.dispatch({ type: "LOGOUT" });
      }
    } else {
      store.dispatch({ type: "LOGOUT" });
    }
  }
  return next(action);
};
