import * as types from "../../constants/lawyer_list/glc_constants";

const initialState = {
  data: null,
  glcError: null,
};

const glcReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_GLC_SUCCESS:
    case types.ADD_GLC_SUCCESS:
    case types.DELETE_GLC_SUCCESS:
      return {
        ...state,
        data: payload ? payload.glcUser : null,
        glcError: null,
      };

    case types.GET_GLC_FAILURE:
    case types.ADD_GLC_FAILURE:
    case types.DELETE_GLC_FAILURE:
      return { ...state, glcError: payload ? payload : null };

    default:
      return state;
  }
};

export default glcReducer;
