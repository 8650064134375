import * as types from "../../constants/um/lawyer_constants";

const initialState = {
  data: null,
  lawyerError: null,
  pag: null,
  filter: null,
};

const lawyerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_LAWYER_SUCCESS:
    case types.RESET_LAWYER_SUCCESS:
      return {
        ...state,
        data: payload ? payload.lawyers : null,
        lawyerError: null,
      };

    case types.UPDATE_LAWYER_SUCCESS:
    case types.DELETE_LAWYER_SUCCESS:
    case types.UPDATE_USER_SETTINGS_SUCCESS:
      return { ...state, message: "Successfull", lawyerError: null };

    case types.FIRST_LAWYER_SUCCESS:
      return { ...state, pag: payload ? payload : null, lawyerError: null };

    case types.FILTER_LAWYER_SUCCESS:
      return { ...state, filter: payload ? payload : null, lawyerError: null };

    case types.GET_LAWYER_FAILURE:
    case types.RESET_LAWYER_FAILURE:
    case types.UPDATE_LAWYER_FAILURE:
    case types.DELETE_LAWYER_FAILURE:
    case types.UPDATE_USER_SETTINGS_FAILURE:
      return { ...state, lawyerError: payload ? payload : null };

    default:
      return state;
  }
};

export default lawyerReducer;
