export const GET_ACTIVE_CHAMBER_SUCCESS = "GET_ACTIVE_CHAMBER_SUCCESS";
export const GET_ACTIVE_CHAMBER_FAILURE = "GET_ACTIVE_CHAMBER_FAILURE";

export const GET_PENDING_CHAMBER_SUCCESS = "GET_PENDING_CHAMBER_SUCCESS";
export const GET_PENDING_CHAMBER_FAILURE = "GET_PENDING_CHAMBER_FAILURE";

export const GET_INACTIVE_CHAMBER_SUCCESS = "GET_INACTIVE_CHAMBER_SUCCESS";
export const GET_INACTIVE_CHAMBER_FAILURE = "GET_INACTIVE_CHAMBER_FAILURE";

export const DELETE_CHAMBER_SUCCESS = "DELETE_CHAMBER_SUCCESS";
export const DELETE_CHAMBER_FAILURE = "DELETE_CHAMBER_FAILURE";

export const UPDATE_CHAMBER_HEAD_SUCCESS = "UDPATE_CHAMBER_HEAD_SUCCESS";
export const UPDATE_CHAMBER_HEAD_FAILURE = "UDPATE_CHAMBER_HEAD_FAILURE";

export const SINGLE_CHAMBER_DETAILS_SUCCESS = "SINGLE_CHAMBER_DETAILS_SUCCESS";
export const SINGLE_CHAMBER_DETAILS_FAILURE = "SINGLE_CHAMBER_DETAILS_FAILURE";

export const GET_CHAMBER_MEMBER_SUCCESS = "GET_CHAMBER_MEMBER_SUCCESS";
export const GET_CHAMBER_MEMBER_FAILURE = "GET_CHAMBER_MEMBER_FAILURE";

export const ADD_CHAMBER_MEMBER_SUCCESS = "ADD_CHAMBER_MEMBER_SUCCESS";
export const ADD_CHAMBER_MEMBER_FAILURE = "ADD_CHAMBER_MEMBER_FAILURE";

export const REMOVE_CHAMBER_MEMBER_SUCCESS = "REMOVE_CHAMBER_MEMBER_SUCCESS";
export const REMOVE_CHAMBER_MEMBER_FAILURE = "REMOVE_CHAMBER_MEMBER_FAILURE";

export const FIRST_ACTIVE_CHAMBER_SUCCESS = "FIRST_ACTIVE_CHAMBER_SUCCESS";
export const FIRST_ACTIVE_CHAMBER_FAILURE = "FIRST_ACTIVE_CHAMBER_FAILURE";

export const FIRST_INACTIVE_CHAMBER_SUCCESS = "FIRST_INACTIVE_CHAMBER_SUCCESS";
export const FIRST_INACTIVE_CHAMBER_FAILURE = "FIRST_INACTIVE_CHAMBER_FAILURE";

export const FILTER_CHAMBER_SUCCESS = "FILTER_CHAMBER_SUCCESS";
export const FILTER_CHAMBER_FAILURE = "FILTER_CHAMBER_FAILURE";

export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_FAILURE = "CHANGE_STATUS_FAILURE";
