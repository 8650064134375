import axios from "axios";
import toast from "react-hot-toast";

// const BASE_URL = "https://gba-test.azurewebsites.net/api/v1";
const BASE_URL = "https://gba2.azurewebsites.net/api/v1";
// const BASE_URL = process.env.REACT_APP_API_BASE_URL_VERSION;

function authInterceptor(req) {
  const accessToken = JSON.parse(localStorage.getItem("profile"))?.token;

  if (accessToken) {
    req.headers.Authorization = accessToken;
  }

  return req;
}

export const API = axios.create({
  baseURL: BASE_URL,
});

API.interceptors.request.use(authInterceptor);

export const handleApiError = async (error) => {
  try {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    const data = null;
    return { error: errorMessage, data };
  } catch (error) {
    throw new Error("An unexpected error occured");
  }
};

export const handleApi = async (method, endpoint, data) => {
  try {
    const response = await API({ method, url: endpoint, data });

    if (response.data) {
      if (method === "delete") {
        toast("Delete Successful");
      } else if (method === "put") {
        toast("Update Successful");
      } else if (method === "patch") {
        toast("Update Successful");
      } else if (method === "post") {
        toast("Successful");
      }
    }

    return { error: null, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};
