import { Spinner } from "@material-tailwind/react";

function SuspenseContainer() {
  return (
    <div className="w-full h-screen">
      <div className="flex items-end gap-8">
        <Spinner className="h-12 w-12" />
      </div>
    </div>
  );
}

export default SuspenseContainer;
