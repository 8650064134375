export const GET_PUPILAGE_SUCCESS = "GET_PUPILAGE_SUCCESS";
export const GET_PUPILAGE_FAILURE = "GET_PUPILAGE_FAILURE";

export const ADD_PUPILAGE_SUCCESS = "ADD_PUPILAGE_SUCCESS";
export const ADD_PUPILAGE_FAILURE = "ADD_PUPILAGE_FAILURE";

export const UPDATE_PUPILAGE_SUCCESS = "UPDATE_PUPILAGE_SUCCESS";
export const UPDATE_PUPILAGE_FAILURE = "UPDATE_PUPILAGE_FAILURE";

export const GET_PUPILAGE_DETAILS_SUCCESS = "GET_PUPILAGE_DETAILS_SUCCESS";
export const GET_PUPILAGE_DETAILS_FAILURE = "GET_PUPILAGE_DETAILS_FAILURE";

export const DELETE_PUPILAGE_SUCCESS = "DELETE_PUPILAGE_SUCCESS";
export const DELETE_PUPILAGE_FAILURE = "DELETE_PUPILAGE_FAILURE";

export const MANUAL_PUPILAGE_SUCCESS = "MANUAL_PUPILAGE_SUCCESS";
export const MANUAL_PUPILAGE_FAILURE = "MANUAL_PUPILAGE_FAILURE";

export const FIRST_PUPILAGE_SUCCESS = "FIRST_PUPILAGE_SUCCESS";
export const FIRST_PUPILAGE_FAILURE = "FIRST_PUPILAGE_FAILURE";

export const FILTER_PUPILAGE_SUCCESS = "FILTER_PUPILAGE_SUCCESS";
export const FILTER_PUPILAGE_FAILURE = "FILTER_PUPILAGE_FAILURE";
