import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import rootReducer from "./reducers";
import { tokenMiddleware } from "../middlewares/token_middleware";
import { initializeAuth } from "./actions/auth_actions";

const createAppStore = async () => {
  try {
    const store = configureStore({
      reducer: rootReducer,
      middleware: [thunk, tokenMiddleware],
    });

    await store.dispatch(initializeAuth());

    return store;
  } catch (error) {
    throw new Error("Some error occured");
  }
};

export default createAppStore;
