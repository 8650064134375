export const PENDING_STICKER_SUCCESS = "PENDING_STICKER_SUCCESS";
export const PENDING_STICKER_FAILURE = "PENDING_STICKER_FAILURE";

export const APPROVED_STICKER_SUCCESS = "APPROVED_STICKER_SUCCESS";
export const APPROVED_STICKER_FAILURE = "APPROVED_STICKER_FAILURE";

export const ALL_STICKER_SUCCESS = "ALL_STICKER_SUCCESS";
export const ALL_STICKER_FAILURE = "ALL_STICKER_FAILURE";

export const UPDATE_STICKER_SUCCESS = "UPDATE_STICKER_SUCCESS";
export const UPDATE_STICKER_FAILURE = "UPDATE_STICKER_FAILURE";
