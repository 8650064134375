import * as types from "../../constants/lawyer_list/black_list_constants";

const initialState = {
  data: null,
  blacklistError: null,
};

const blackListReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_BLACK_LIST_SUCCESS:
    case types.UPDATE_BLACK_LIST_SUCCESS:
    case types.ADD_BLACK_LIST_SUCCESS:
    case types.DELETE_BLACK_LIST_SUCCESS:
      return {
        ...state,
        data: payload ? payload.blackListUser : null,
        blacklistError: null,
      };

    case types.GET_BLACK_LIST_FAILURE:
    case types.ADD_BLACK_LIST_FAILURE:
    case types.UPDATE_BLACK_LIST_FAILURE:
    case types.DELETE_BLACK_LIST_FAILURE:
      return { ...state, blacklistError: payload ? payload : null };

    default:
      return state;
  }
};

export default blackListReducer;
