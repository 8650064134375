import { Typography } from "@material-tailwind/react";
import React from "react";

function Dashboard() {
  return (
    <>
      <Typography variant="h1" className="no-content">
        Welcome
      </Typography>
      ;
    </>
  );
}

export default Dashboard;
