import { Alert } from "@material-tailwind/react";

function AlertMessage({ type, message }) {
  const getColorClass = (type) => {
    switch (type) {
      case "info":
        return "blue";
      case "error":
        return "red";
      case "success":
        return "green";
      case "warning":
        return "amber";
      default:
        return "blue";
    }
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <Alert color={getColorClass(type)}>{message}</Alert>
    </div>
  );
}

export default AlertMessage;
