import { IconButton, Tooltip } from "@material-tailwind/react";
import React from "react";

function MoreDetailsIconButton({ onClick }) {
  return (
    <Tooltip content="More Details">
      <IconButton size="sm" className="color-orange-50 ml-2" onClick={onClick}>
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.25 3C1.25 2.58579 1.58579 2.25 2 2.25L22 2.25C22.4142 2.25 22.75 2.58579 22.75 3C22.75 3.41422 22.4142 3.75 22 3.75L2 3.75C1.58579 3.75 1.25 3.41421 1.25 3ZM7.94801 7.25H16.052C16.9505 7.24997 17.6997 7.24995 18.2945 7.32991C18.9223 7.41432 19.4891 7.59999 19.9445 8.05546C20.4 8.51093 20.5857 9.07773 20.6701 9.70552C20.7501 10.3003 20.75 11.0495 20.75 11.948V12.052C20.75 12.9505 20.7501 13.6997 20.6701 14.2945C20.5857 14.9223 20.4 15.4891 19.9445 15.9445C19.4891 16.4 18.9223 16.5857 18.2945 16.6701C17.6997 16.7501 16.9505 16.75 16.052 16.75H7.94801C7.04952 16.75 6.3003 16.7501 5.70552 16.6701C5.07773 16.5857 4.51093 16.4 4.05546 15.9445C3.59999 15.4891 3.41432 14.9223 3.32991 14.2945C3.24995 13.6997 3.24997 12.9505 3.25 12.052V11.948C3.24997 11.0495 3.24995 10.3003 3.32991 9.70552C3.41432 9.07773 3.59999 8.51093 4.05546 8.05546C4.51093 7.59999 5.07773 7.41432 5.70552 7.32991C6.3003 7.24995 7.04952 7.24997 7.94801 7.25ZM5.90539 8.81654C5.44393 8.87858 5.24643 8.9858 5.11612 9.11612C4.9858 9.24644 4.87858 9.44393 4.81654 9.90539C4.75159 10.3884 4.75 11.036 4.75 12C4.75 12.964 4.75159 13.6116 4.81654 14.0946C4.87858 14.5561 4.9858 14.7536 5.11612 14.8839C5.24643 15.0142 5.44393 15.1214 5.90539 15.1835C6.38843 15.2484 7.03599 15.25 8 15.25H16C16.964 15.25 17.6116 15.2484 18.0946 15.1835C18.5561 15.1214 18.7536 15.0142 18.8839 14.8839C19.0142 14.7536 19.1214 14.5561 19.1835 14.0946C19.2484 13.6116 19.25 12.964 19.25 12C19.25 11.036 19.2484 10.3884 19.1835 9.90539C19.1214 9.44393 19.0142 9.24644 18.8839 9.11612C18.7536 8.9858 18.5561 8.87858 18.0946 8.81654C17.6116 8.7516 16.964 8.75 16 8.75H8C7.03599 8.75 6.38843 8.7516 5.90539 8.81654ZM1.25 21C1.25 20.5858 1.58579 20.25 2 20.25L22 20.25C22.4142 20.25 22.75 20.5858 22.75 21C22.75 21.4142 22.4142 21.75 22 21.75L2 21.75C1.58579 21.75 1.25 21.4142 1.25 21Z"
              fill="#ff5722
"
            ></path>{" "}
          </g>
        </svg>
      </IconButton>
    </Tooltip>
  );
}

export default MoreDetailsIconButton;
