import { Card, Typography } from "@material-tailwind/react";
import React from "react";

function PageTitle({ title, subheading, icon }) {
  return (
    <div className="app-page-title">
      <div className="page-title-heading">
        <Card className="page-title-icon p-2 rounded-lg bg-white shadow-md">
          {icon}
        </Card>
        <div>
          {title && <Typography variant="h3">{title}</Typography>}
          {subheading && (
            <Typography variant="paragraph" className="opacity-60">{subheading} Items</Typography>
          )}
        </div>
      </div>
    </div>
  );
}

export default PageTitle;
