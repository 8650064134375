import { Suspense, useRef } from "react";
import { Route, Routes } from "react-router-dom";

import routes from "../routes/index";

import SuspenseContainer from "./SuspenseContainer";

function PageContent() {
  const mainContentRef = useRef(null);

  return (
    <div className="drawer-content flex flex-col w-full">
      <main
        className="flex-1 overflow-y-auto pt-8 px-6  bg-base-200"
        ref={mainContentRef}
      >
        <Suspense fallback={<SuspenseContainer />}>
          <Routes>
            {routes.map((route, key) => {
              return (
                <Route
                  key={key}
                  exact={true}
                  path={`${route.path}`}
                  element={<route.component />}
                />
              );
            })}
          </Routes>
        </Suspense>
      </main>
    </div>
  );
}

export default PageContent;
