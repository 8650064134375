import { lazy } from "react";
import Dashboard from "../containers/Dashboard";
import StandingProductYear from "../pages/standing/StandingProductYear";
import StandingYear from "../pages/standing/StandingYear";
import NotStanding from "../pages/standing/NotStanding";

const Whitelist = lazy(() => import("../pages/lawyer_list/Whitelist"));
const Blacklist = lazy(() => import("../pages/lawyer_list/BlackList"));
const Barredlist = lazy(() => import("../pages/lawyer_list/BarredList"));
const DuesExempt = lazy(() => import("../pages/lawyer_list/DuesExempt"));
const GLC = lazy(() => import("../pages/lawyer_list/Glc"));
const ManualGreenlist = lazy(() =>
  import("../pages/lawyer_list/ManualGreenList")
);
const DeptDuesExempt = lazy(() =>
  import("../pages/lawyer_list/DeptDuesExempt")
);

const Lawyers = lazy(() => import("../pages/user_management/Lawyers"));
const Pupils = lazy(() => import("../pages/user_management/Pupils"));
const GoodStanding = lazy(() =>
  import("../pages/user_management/GoodStanding")
);
const UserSettings = lazy(() =>
  import("../pages/user_management/components/UserSettings")
);

const ActiveChambers = lazy(() => import("../pages/chambers/ActiveChambers"));
const PendingChambers = lazy(() => import("../pages/chambers/PendingChambers"));
const InActiveChambers = lazy(() =>
  import("../pages/chambers/InActiveChambers")
);
const ChamberDetails = lazy(() => import("../pages/chambers/ChamberDetails"));
const ChamberEdit = lazy(() => import("../pages/chambers/EditChamberDetails"));

const PendingStickers = lazy(() => import("../pages/stickers/PendingStickers"));
const ApprovedStickers = lazy(() =>
  import("../pages/stickers/ApprovedStickers")
);
const AllStickers = lazy(() => import("../pages/stickers/AllStickers"));

const Conference = lazy(() => import("../pages/conference/AllConference"));
const ConferenceList = lazy(() => import("../pages/conference/ConferenceList"));
const ConferenceNeeds = lazy(() =>
  import("../pages/conference/ConferenceNeeds")
);
const ConferenceCategory = lazy(() =>
  import("../pages/conference/ConferenceCategory")
);
const SingleConference = lazy(() =>
  import("../pages/conference/SingleConference")
);
const ConferenceExempt = lazy(() =>
  import("../pages/conference/ConferenceExempt")
);

const Dues = lazy(() => import("../pages/payments/dues/Dues"));
const SingleDues = lazy(() => import("../pages/payments/dues/SingleDues"));

const License = lazy(() => import("../pages/payments/license/License"));
const SingleLicense = lazy(() =>
  import("../pages/payments/license/SingleLicense")
);

const Chamber = lazy(() => import("../pages/payments/chamber/chamber_payment"));
const SingleChamber = lazy(() =>
  import("../pages/payments/chamber/SingleChamberPayment")
);

const Pupilage = lazy(() => import("../pages/payments/pupilage/Pupilage"));
const SinglePupilage = lazy(() =>
  import("../pages/payments/pupilage/SinglePupilage")
);

const Stickers = lazy(() => import("../pages/payments/stickers/Stickers"));

const BootcampList = lazy(() => import("../pages/bootcamp/BootcampList"));
const SingleBootcamp = lazy(() => import("../pages/bootcamp/SingleBootcamp"));

const routes = [
  { path: "/", component: Dashboard },
  { path: "/whitelist", component: Whitelist },
  { path: "/blacklist", component: Blacklist },
  { path: "/dues-exempt", component: DuesExempt },
  { path: "/dept-dues-exempt", component: DeptDuesExempt },
  { path: "/manual-green-list", component: ManualGreenlist },
  { path: "/barredlist", component: Barredlist },
  { path: "/glc", component: GLC },

  { path: "/lawyers", component: Lawyers },
  { path: "/pupils", component: Pupils },
  { path: "/good", component: GoodStanding },
  { path: "/lawyers/settings/:id", component: UserSettings },

  { path: "/active-chamber", component: ActiveChambers },
  { path: "/pending-chamber", component: PendingChambers },
  { path: "/inactive-chamber", component: InActiveChambers },
  { path: "/chamber/details/:id", component: ChamberDetails },
  { path: "/chamber/edit/:id", component: ChamberEdit },

  { path: "/stickers/pending", component: PendingStickers },
  { path: "/stickers/approved", component: ApprovedStickers },
  { path: "/stickers/all", component: AllStickers },

  { path: "/dues", component: Dues },
  { path: "/dues/details/:id", component: SingleDues },

  { path: "/license", component: License },
  { path: "/license/details/:id", component: SingleLicense },

  { path: "/chamber-payment", component: Chamber },
  { path: "/chamber-payment/details/:id", component: SingleChamber },

  { path: "/pupilage", component: Pupilage },
  { path: "/pupilage-pay/details/:id", component: SinglePupilage },

  { path: "/conference", component: Conference },
  { path: "/all-conference", component: Conference },
  { path: "/exempt", component: ConferenceExempt },
  { path: "/conference/category/:id", component: ConferenceCategory },
  { path: "/conference/needs/:id", component: ConferenceNeeds },
  { path: "/conference/list/:id/:value/:category", component: ConferenceList },
  {
    path: "/all-conference/single-conference/:id",
    component: SingleConference,
  },

  { path: "/stickers", component: Stickers },

  { path: "/standing", component: StandingProductYear },
  { path: "/standing/:id", component: StandingYear },
  { path: "/not/standing", component: NotStanding },

  { path: "/bootcamp", component: BootcampList },
  { path: "/bootcamp/:year", component: SingleBootcamp },
];

export default routes;
