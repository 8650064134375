import * as types from "../../constants/lawyer_list/dept_dues_constants";

const initialState = {
  data: null,
  deptError: null,
};

const deptDuesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_DEPT_DUES_SUCCESS:
    case types.ADD_DEPT_DUES_SUCCESS:
    case types.DELETE_DEPT_DUES_SUCCESS:
    case types.UPLOAD_DEPT_DUES_SUCCESS:
      return {
        ...state,
        data: payload ? payload.duesUser : null,
        deptError: null,
      };

    case types.GET_DEPT_DUES_FAILURE:
    case types.ADD_DEPT_DUES_FAILURE:
    case types.DELETE_DEPT_DUES_FAILURE:
    case types.UPLOAD_DEPT_DUES_FAILURE:
      return { ...state, deptError: payload ? payload : null };

    default:
      return state;
  }
};

export default deptDuesReducer;
