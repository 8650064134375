import createAsyncAction from "../../../utils/async_action_utils";
import * as api from "../../api/um/good_standing_api";
import * as types from "../../constants/um/good_standing_constants";

const getGoodStandingTypes = {
  success: types.GET_GOODSTANDING_SUCCESS,
  failure: types.GET_GOODSTANDING_FAILURE,
};

const standingProductTypes = {
  success: types.STANDING_PRODUCT_SUCCESS,
  failure: types.STANDING_PRODUCT_FAILURE,
};

const noStandingTypes = {
  success: types.NOT_GOODSTANDING_SUCCESS,
  failure: types.NOT_GOODSTANDING_SUCCESS,
};

const filterStandingTypes = {
  success: types.FILTER_GOODSTANDING_SUCCESS,
  failure: types.FILTER_GOODSTANDING_SUCCESS,
};

export const getGoodStanding = createAsyncAction(
  api.getGoodStanding,
  getGoodStandingTypes
);

export const standingProduct = createAsyncAction(
  api.standingProduct,
  standingProductTypes
);

export const noStanding = createAsyncAction(api.noStanding, noStandingTypes);

export const filterStanding = createAsyncAction(
  api.filterStanding,
  filterStandingTypes
);
