import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { noStanding } from "../../redux/actions/um/good_standing_action";
import DataTable from "../../common/shared/DataTable";
import DownloadButton from "../../common/buttons/DownloadButton";
import saveDownload from "../../utils/downloadFile";
import PageTitle from "../../common/shared/PageTitle";
import formatNumber from "../../utils/formatNumber";
import Loader from "../../common/loaders/Loader";
import AlertMessage from "../../common/actions/AlertMessage";
import UMIcon from "../../common/icons/UMIcon";

function NotStanding() {
  const [loading, setLoading] = useState(true);

  const { not, goodError } = useSelector((state) => state.good);

  const dispatch = useDispatch();

  const columns = [
    {
      field: "name",
      headerName: "Full Name",
      flex: 1,
    },
    { field: "bar_number", headerName: "Bar Number", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone", headerName: "Phone", flex: 1 },
    { field: "doc", headerName: "Date Of Call", flex: 1 },
    // { field: "date_paid", headerName: "Date Paid", flex: 1 },
  ];

  useEffect(() => {
    dispatch(noStanding()).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <>
      {goodError && <AlertMessage type="error" message={goodError} />}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between p-4">
            <div className="flex items-center">
              <PageTitle
                title="Not In GoodStanding List"
                subheading={
                  formatNumber(not.length)
                  //   showFilter ? filteredResult.length : data.length
                }
                icon={<UMIcon />}
              />
            </div>

            <div className="flex items-center gap-4">
              <DownloadButton
                onClick={() =>
                  saveDownload(
                    // showFilter ? filteredResult : data,
                    "Not In Good Standing"
                  )
                }
              />

              {/* <FilterButton onClick={() => setFilterModal(true)} />
              {showFilter && (
                <ClearFilterButton onClick={() => setShowFilter(false)} />
              )} */}
            </div>
          </div>

          <DataTable
            rows={not}
            columns={columns}
            // showFilter={showFilter}
            // dateFormat={["date_paid"]}
            // filteredResult={filteredResult}
          />

          {/* {filterModal && (
            <FilterModal
              columns={["first_name", "last_name", "bar_number", "amount"]}
              list={data}
              onFilter={handleFilter}
              onClose={() => setFilterModal(false)}
            />
          )} */}
        </>
      )}
    </>
  );
}

export default NotStanding;
