export const GET_DUES_SUCCESS = "GET_DUES_SUCCESS";
export const GET_DUES_FAILURE = "GET_DUES_FAILURE";

export const ADD_DUES_SUCCESS = "ADD_DUES_SUCCESS";
export const ADD_DUES_FAILURE = "ADD_DUES_FAILURE";

export const UPDATE_DUES_SUCCESS = "UPDATE_DUES_SUCCESS";
export const UPDATE_DUES_FAILURE = "UPDATE_DUES_FAILURE";

export const GET_DUES_DETAILS_SUCCESS = "GET_DUES_DETAILS_SUCCESS";
export const GET_DUES_DETAILS_FAILURE = "GET_DUES_DETAILS_FAILURE";

export const MANUAL_DUES_SUCCESS = "MANUAL_DUES_SUCCESS";
export const MANUAL_DUES_FAILURE = "MANUAL_DUES_FAILURE";

export const DELETE_DUES_SUCCESS = "DELETE_DUES_SUCCESS";
export const DELETE_DUES_FAILURE = "DELETE_DUES_FAILURE";

export const FIRST_DUES_SUCCESS = "FIRST_DUES_SUCCESS";
export const FIRST_DUES_FAILURE = "FIRST_DUES_FAILURE";

export const FILTER_DUES_SUCCESS = "FILTER_DUES_SUCCESS";
export const FILTER_DUES_FAILURE = "FILTER_DUES_FAILURE";
