import * as types from "../../constants/stickers/stickers_constants";

const initialState = {
  data: null,
  pending: null,
  approved: null,
  stickerError: null,
  message: "",
};

const stickerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.ALL_STICKER_SUCCESS:
      return {
        ...state,
        data: payload ? payload.stickers : null,
        stickerError: null,
      };

    case types.UPDATE_STICKER_SUCCESS:
      return { ...state, message: "Done" };

    case types.PENDING_STICKER_SUCCESS:
      return {
        ...state,
        pending: payload ? payload.stickers : null,
        stickerError: null,
      };

    case types.APPROVED_STICKER_SUCCESS:
      return {
        ...state,
        approved: payload ? payload.stickers : null,
        stickerError: null,
      };

    case types.PENDING_STICKER_FAILURE:
    case types.ALL_STICKER_FAILURE:
    case types.APPROVED_STICKER_FAILURE:
    case types.UPDATE_STICKER_FAILURE:
      return { ...state, data: payload ? payload : null };

    default:
      return state;
  }
};

export default stickerReducer;
