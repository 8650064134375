import * as types from "../../constants/chamber/chamber_constants";

const initialState = {
  data: null,
  chamberError: null,
  count: null,
  details: null,
  members: null,
  pag: null,
  filter: null,
  active: null,
  pendingPag: null,
  activePag: null,
  inactive: null,
  pending: null,
};

const chamberReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_INACTIVE_CHAMBER_SUCCESS:
    case types.UPDATE_CHAMBER_HEAD_SUCCESS:
      return {
        ...state,
        data: payload ? payload.chamber : null,
        count: payload ? payload.count : null,
        chamberError: null,
      };

    case types.GET_PENDING_CHAMBER_SUCCESS:
      return {
        ...state,
        pending: payload ? payload.chamber : null,
        count: payload ? payload.count : null,
        chamberError: null,
      };

    case types.GET_ACTIVE_CHAMBER_SUCCESS:
      return {
        ...state,
        active: payload ? payload.chamber : null,
        count: payload ? payload.count : null,
        chamberError: null,
      };

    case types.SINGLE_CHAMBER_DETAILS_SUCCESS:
      return {
        ...state,
        details: payload ? payload.chamber : null,
        chamberError: null,
      };

    case types.CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        message: payload ? payload.message : null,
        chamberError: null,
      };

    case types.FIRST_INACTIVE_CHAMBER_SUCCESS:
      return {
        ...state,
        pag: payload ? payload.chamber : null,
        chamberError: null,
      };

    case types.FIRST_ACTIVE_CHAMBER_SUCCESS:
      return {
        ...state,
        activePag: payload ? payload.chamber : null,
        chamberError: null,
      };

    case types.FILTER_CHAMBER_SUCCESS:
      return {
        ...state,
        filter: payload ? payload.chamber : null,
        chamberError: null,
      };

    case types.GET_CHAMBER_MEMBER_SUCCESS:
    case types.ADD_CHAMBER_MEMBER_SUCCESS:
    case types.REMOVE_CHAMBER_MEMBER_SUCCESS:
      return {
        ...state,
        members: payload ? payload.members : null,
        chamberError: null,
      };

    case types.GET_CHAMBER_MEMBER_FAILURE:
    case types.ADD_CHAMBER_MEMBER_FAILURE:
    case types.REMOVE_CHAMBER_MEMBER_FAILURE:
      return {
        ...state,
        chamberError: payload ? payload : null,
      };
    case types.DELETE_CHAMBER_SUCCESS:
      return { ...state };

    case types.GET_ACTIVE_CHAMBER_FAILURE:
    case types.GET_PENDING_CHAMBER_FAILURE:
    case types.GET_INACTIVE_CHAMBER_FAILURE:
    case types.DELETE_CHAMBER_FAILURE:
    case types.UPDATE_CHAMBER_HEAD_FAILURE:
    case types.SINGLE_CHAMBER_DETAILS_FAILURE:
    case types.FILTER_CHAMBER_FAILURE:
    case types.CHANGE_STATUS_FAILURE:
      return {
        ...state,
        chamberError: payload ? payload : null,
        count: payload ? payload.count : null,
      };

    default:
      return state;
  }
};

export default chamberReducer;
